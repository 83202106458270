import React from 'react'
import BackgroundImage from 'gatsby-background-image'

const Hero = ({ image, title, tagline, button }) => {
  return (
    <BackgroundImage 
      className="bg-no-repeat bg-top bg-cover overflow-hidden index-bg text-center px-8 text-white"
      fluid={[
        "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))",
        image.childImageSharp.fluid,
    ]}>
      <div className="index-hero mt-40 mx-auto max-w-screen-xl px-4 sm:mt-32 sm:px-6 md:mt-36 lg:mt-36 xl:mt-44">
        <h1 className="text-4xl tracking-tight leading-10 font-extrabold text-gray-100 sm:text-6xl sm:leading-none md:text-7xl mb-8">{title}</h1>
        <h4 className="mt-3 max-w-md mx-auto text-base font-bold text-blue-100 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl mb-5">{tagline}</h4>
        <br />
        <a href={button.link} target="_blank" className="items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-blue-700 bg-blue-100 hover:bg-blue-200 md:py-4 md:text-lg md:px-10">{button.label}</a>
      </div>
    </BackgroundImage>
  )
}

export default Hero